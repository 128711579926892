import React from "react";
import { useNavigate } from "react-router-dom";
import "../App.css";

const mainBg = require("../img/main-bg.png");

function PrivacyPolicyComponent() {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate("/");
  };

  return (
    <div className="app" style={{ backgroundImage: `url(${mainBg})` }}>
      <div className="privacy-policy-container">
        <h1>Privacy Policy</h1>
        <div className="privacy-content">
          <p>This privacy policy ("Policy") informs you of your choices and our practices in relation to your Information (as defined below). For the purposes of this Policy, "we", "us" and "our" refers to "W-Coin LLC" Registration Number: 3178 with its office located at Beachmont, Kingstown, Saint Vincent and the Grenadines</p>

          <h2>1. PERSONAL INFORMATION WE COLLECT</h2>
          <p>We may collect and use the following Information about you:</p>

          <p>• Information you provide to us: We receive and store information provided to us or given to us in any other way, including your: name, mailing address, phone number, email address, picture, date of birth, payment information, registration information, social media and messaging platform handle, optional biographic and demographic information, processional and licensure information, information for wallets you create or connect via our Websites, survey responses, and any other information that you voluntarily provide. This includes information that you share with us on third-party websites and platforms.</p>

          <p>• Information collected via our customer support channels, for example, when you contact us via email, you may provide us with (a) your full name, email and (b) any information you choose to provide to allow us to assist you. This information is not used or shared for any purpose other than to assist with your reason for contact.</p>

          <p>• Information you provide when using the app or website: You are only required to submit personal information, should you choose to opt into marketing emails, such as newsletters and updates.</p>

          <p>• Information provided: It is the responsibility of the you, the 'user' to ensure your details are accurate and up to date and, where possible, to only provide such information as is necessary when you contact us.</p>

          <p>Information we automatically collect or is generated about you when you, register for newsletters or updates or connect to our app or website:</p>

          <p>• Identifiers, such as your name, email address, IP address, device and app ID, unique ID, location data and device information (such as model, brand and operating system). • Cookies: we use cookies and other similar technologies (e.g. web beacons, log files, and scripts) ("Cookies") to enhance your experience when using our services. Cookies are small files which, when placed on your device, enable us to provide certain features and functionality. You have the option to permit installation of such Cookies or subsequently disable them. You may accept all cookies or instruct the device or web browser to provide notice at the time of installation of cookies or refuse to accept all cookies by adjusting the relevant cookie retention function in your device. However, in the event of your refusal to install cookies, the Game may be unable to operate as designed. For more information about our Cookies policy, click here.</p>

          <p>• Information regarding your use of the website or app, such as date and time stamps of events, interactions with our teams.</p>

          <p>• Location based data - Using an app: is collected within the app and can only be collected if you, the 'user' has your location services activated. When the app is installed it will ask for permission to allow the app to access your location service, you can accept or deny. You can also go into your settings on your phone and disable this at any time. Website: When you visit our Websites or interact with our online services, we may receive information about your location and your device, including a unique identifier for your device. Location information allows us to provide location-based services, such as advertising and other personalised content.</p>

          <p>Our Websites may use "cookies," (please refer to our Cookies Policy) tagging and other tracking technologies to help enable us to enhance or personalise your online experience. This information includes computer and connection information such as statistics on your page views, traffic to and from our Websites, referral URL, ad data, your IP address, device identifiers, transaction history, and your web log information.</p>

          <p>Information received from third parties:</p>

          <p>Information from Social Media: When you interact with us or our Websites or on a social media platform, we may collect the personal information that you make available to us, including your account ID, username, and other information included in your posts. If you choose to log into your account with or through a social networking service, we and that service may share certain information about you and your activities. When you give us permission, we may also collect information from your social media account on your behalf.</p>

          <p>• Analytics information: We integrate certain analytic software, Google Analytics, a third-party analytics provider. They provide reports that help us optimize our features. This information may include user activity but is not identifiable information.</p>

          <p>• Information from mobile measurement partners: We receive information from third parties to allow us to track performance and to detect fraud. This includes IP address, location, and in some circumstances transaction information.</p>

          <p>• Third-Party Terms and Policies: When connecting your virtual wallet to our app or website to login, third-party terms or policies may apply. It remains the responsibility of the user to ensure you have read and agree to their terms.</p>

          <p>We do not collect any Special Categories of Personal Data about you (this includes details about your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade union membership, information about your health, and genetic and biometric data). Nor do we collect any information about criminal convictions and offenses.</p>

          <h2>2. HOW WE USE YOUR PERSONAL INFORMATION</h2>
          <p>We will only use your personal data (such as name, email address, or telephone number if provided to us) when the law allows us to. Most commonly, we will use your personal data in the following circumstances:</p>

          <p>a) Where we need to perform the contract, we are about to enter into or have entered into with you.</p>

          <p>b) Where it is necessary for our legitimate interests (or those of a third party) and your interests and fundamental rights do not override those interests.</p>

          <p>c) Where we need to comply with a legal obligation.</p>

          <p>d) Or, where you have opted in to receive any marketing material.</p>

          <p>Certain purposes that either 'constitute' a legitimate interest or 'should be regarded as' a legitimate interest. These are: fraud prevention; network and information security; and indicating possible criminal acts or threats to public security.</p>

          <p>Some processing is necessary because it is in our or a third party's legitimate interests, such as those of visitors, members, or partners.</p>

          <p>We may collect information in a way that does not directly identify you; we may collect information you have shared with us and we may use and share such information as necessary for our business purposes and as permitted by applicable law.</p>

          <h3>The Lawful Bases we rely on and our legitimate interests</h3>

          <p>Provide you with the service. More in particular, we will use Information to perform our contractual obligation towards you to allow you to connect to our services via your virtual wallet. The Information we process when doing so may include a unique identifier which does not identify you personally.</p>

          <p>Improve and monitor use. To improve our service for our customers. When doing so, we may collect information such as a unique identifier which will allow us to analyze information about your device such as battery, Wi-Fi strength, device manufacturer, model, and operating system.</p>

          <p>Provide you with support and respond to your requests or complaints. If you reach out to us for support, we will use your Information to respond and resolve your queries and complaints, facilitate support. When doing so, we perform our contractual obligation towards you.</p>

          <p>Conduct analytics. To analyze interactions and (a) create anonymized and aggregated data; (b) create segments of users who show particular characteristics or interests; and (c) conduct predictive analytics about your interests.</p>

          <p>Provide you with advertising. We will present you with newsletters, advertising updates, and/or offers. Where it is required, we will only do so where we have your consent. In situations where your consent is not required, or where we provide contextual advertising, we do so on the basis of our legitimate interests. If you no longer want to receive targeted advertising, please see our Cookie Policy which explains how you can opt out and change your browser and device settings.</p>

          <p>Prevent fraud, defend W-coin against legal claims or disputes, enforce our terms and comply with our legal obligations. To detect fraud or any other user behavior which prejudices the integrity of our services, (2) taking steps to remedy aforementioned fraud and behavior, (3) defending ourselves against legal claims or disputes, and (4) enforcing our terms and policies. When doing so, we will process the Information relevant in such a case, including information you provide us, information we automatically collect about you, and information which is provided to us by third parties.</p>

          <h2>3. WHO WE SHARE YOUR PERSONAL INFORMATION WITH:</h2>
          <p>Vendors and service providers, we rely on for the provision of the service, for example:</p>

          <p>• Cloud service providers who rely on for data storage, being AWS (Amazon Web Server)</p>
          <p>• Analytics providers. We work with a number of analytics, segmentation and mobile measurement service providers who help us understand our userbase. This includes Apple, Google, AWS (Amazon Web Server).</p>
          <p>• Advertising partners. We may include an ad-supported service. Subject to your settings, we provide certain Information to advertisers who will use them to serve you with ads within the app or website, and we measure who sees and clicks on their ads. We also share advertising identifiers, along with the interests or other characteristics of a device or the person using it, to help partners decide whether to serve an ad to that device or to enable them to conduct marketing, brand analysis, personalize advertising, or similar activities. For more information on how to limit or opt-out from personalized advertisement, please see our Cookie Policy</p>
          <p>• Partner Exchanges: These processors are responsible for the processing of your Information and may use your Information for their own purposes in accordance with their privacy policies, please refer to their individual policies.</p>
          <p>• Law enforcement agencies, public authorities or other judicial bodies and organisations. We disclose Information if we are legally required to do so, or if we have a good faith belief that such use is reasonably necessary to comply with a legal obligation, process or request; enforce our terms of service and other agreements, policies, and standards, including investigation of any potential violation thereof; detect, prevent or otherwise address security, fraud or technical issues; or protect the rights, property or safety of us, our users, a third party or the public as required or permitted by law (including exchanging information with other companies and organisations for the purposes of fraud protection).</p>
          <p>• Change of corporate ownership. If we are involved in a merger, acquisition, bankruptcy, reorganisation, partnership, asset sale or other transaction, we may disclose your Information as part of that transaction.</p>

          <h3>Third Party Privacy Practices</h3>
          <p>If you access any service through a third-party platform such as Apple or Google ("Third Party Services"), you must understand that those Third Party Services may collect other information about you (including information you share with them directly or about your use of the app or website) in accordance with their own terms and privacy policies. The privacy practices described in this Policy do not apply to Third Party Services. Any links to Third Party Services do not imply that we endorse or have reviewed the Third-Party Services.</p>

          <h3>Security</h3>
          <p>Although we have in place security measures to maintain the privacy and integrity of your Information, unfortunately, the transmission of Information via the internet is not completely secure. We may also take extra steps to protect your Information and minimise the Information we process.</p>

          <h2>4. WHERE WE PROCESS YOUR PERSONAL INFORMATION</h2>
          <p>Your Information will be processed by our employees and service providers, Apple, Google, AWS (Amazon Web Services) and Mailchimp. We take steps to ensure all transfers are protected by adequate safeguards When you download the app via Google Play or Apple, you will need to read their Terms and Policies which are independent of W-coins Terms and Policies. We may share with Google, Apple, AWS (Amazon Web Services) data we have collected from your device in order to track user experience, such as app or website crashes. This information does not include identifiable or personal information.</p>

          <p>It is unlikely, however, should we need to transfer your personal data out of the Saint Vincent and the Grenadines, we ensure a similar degree of protection is afforded to it by ensuring at least one of the following safeguards is implemented:</p>

          <p>We will only transfer your data to countries that have been deemed to provide an adequate level of protection for personal data.</p>

          <h2>5. HOW LONG WE STORE YOUR INFORMATION</h2>
          <p>Your Information, provided to us, by you, will be stored for up to 6 years. When deleting Information, we will take measures to make the Information irrecoverable or irreproducible, and electronic files which contain Information will be deleted permanently.</p>

          <h2>6. YOUR RIGHTS</h2>
          <p>Under certain circumstances, you have rights under data protection laws in relation to your personal data. These rights are:</p>

          <p>• The right to request access to your personal data</p>
          <p>• The right to request correction of your personal data</p>
          <p>• The right to request erasure of your personal data</p>
          <p>• The right to object to processing of your personal data</p>
          <p>• The right to request restriction of processing your personal data</p>
          <p>• The right to request transfer of your personal data</p>
          <p>• The right to withdraw consent</p>

          <p>You will not have to pay a fee to access your personal data (or to exercise any of the other rights). However, we may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive. Alternatively, we could refuse to comply with your request in these circumstances.</p>

          <p>We may need to request specific information from you to help us confirm your identity and ensure your right personal data is not disclosed to any person who has no right to receive it.</p>

          <p>We try to respond to all legitimate requests within one month. Occasionally, it could take us longer than a month if your request is particularly complex or you have made a number of requests. In this case, we will notify you and keep you updated.</p>

          <p>If you are based in the EEA, Switzerland or are a legal resident of California in the U.S., you have certain rights in relation to your Information. For Californian residents, please refer to Addendum 1 – California Privacy Rights. For Brazilian residents, please refer to Addendum 2 – Brazil Privacy Rights. For EEA and Switzerland based, you will find more information below on when which rights can apply.</p>

          <p>• Access. You have the right to access Information, and to receive an explanation of how we use it and who we share it with. This right is not absolute. For example, we cannot reveal trade secrets or give you Information about other individuals.</p>

          <p>• Erasure. You have the right to request deletion of your Information. We may need to retain some of your Information where there are valid grounds for us to do so under data protection laws. For example, for the defence of legal claims, respect freedom of expression, or where we have an overriding legitimate interest to do so, but we will let you know when this is the case. Note that where the Information is held by a third party data controller, such as an advertising partner or a payment processor, we will use reasonable steps to inform them of your request, but we recommend you contact them directly in accordance with their own privacy policies to ensure your personal data is erased.</p>

          <p>• Objection and withdrawal of consent: You have the right to (i) withdraw your consent where you previously provided such consent; or (ii) object to our processing of your Information where we process such Information on the basis of our legitimate interests (see How we use your personal information). You may exercise this right as follows:</p>

          <p>- To stop receiving marketing emails: please follow the unsubscribe mechanism at the bottom of each email communication.</p>
          <p>- To stop our cookies being placed: please refer to our Cookie Policy.</p>
          <p>- To stop receiving push notifications: please change your device or browser settings.</p>

          <p>• Portability. You have the right to receive a copy of Information we process on the basis of consent or contract in a structured, commonly used and machine-readable format, or to request that such Information is transferred to a third party.</p>

          <p>• Correction. You have the right to correct any Information held about you that is inaccurate.</p>
          <p>• Restriction. You have a right in certain circumstances to stop us processing Information other than for storage purposes.</p>

          <h2>ADDENDUM 1 – CALIFORNIA PRIVACY RIGHTS</h2>
          <p>The terms of this Addendum apply to residents of California under the California Consumer Privacy Act of 2018 and its implementing regulations, as amended or superseded from time to time ("CCPA"). For the purposes of this addendum, Personal Information means information that identifies, relates to, describes, is reasonably capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular consumer or household, or as otherwise defined by the CCPA. Personal Information does not include information that is: lawfully made available from government records, deidentified or aggregated, or otherwise excluded from the scope of the CCPA.</p>

          <h3>Collection and Disclosure of Personal Information</h3>
          <p>Over the course of 12 months, through your use of our app and / or website, we may collect and disclose the following categories of Personal Information from or about you:</p>

          <p>• Identifiers, including name, email address, IP address, device identifiers, game user ID. This information is collected directly from you or your device. If you have registered through a third-party account (Apple or Google), we may have also collected from those third-party services your third-party ID.</p>
          <p>• Internet or other electronic network activity information, including your use of features. This information is collected from our selected third-party analytics providers and advertising partners.</p>
          <p>• Geolocation data. This information is collected directly from you or your device and from third party services when you register through them.</p>
          <p>• Commercial information, including records of products or services purchased, obtained, or considered, your Apple ID number for Apple, your postcode and state for Google. This information is collected directly from you or your device, and from our payment processors.</p>

          <p>We collect personal information for the following purposes:</p>

          <p>• To operate and administer the services;</p>
          <p>• To improve the services;</p>
          <p>• To communicate with you;</p>
          <p>• For security and verification purposes, including to prevent and detect fraudulent activity;</p>
          <p>• To address and remediate technical issues and bugs.</p>

          <p>We may disclose personal information to the following types of entities:</p>

          <p>• Other companies that provide services on our behalf who are prohibited by contract from retaining, using, or disclosing personal information for any purpose other than for providing the services to us;</p>
          <p>• Regulators, judicial authorities and law enforcement agencies;</p>
          <p>• Entities that acquire all or substantially all of our business.</p>

          <h2>ADDENDUM 2 – BRAZIL PRIVACY RIGHTS</h2>
          <p>The terms of this Addendum apply to residents of Brazil under the Lei Geral de Proteção de Dados (Lei nº 13.709, de 14 de agosto de 2018) and its implementing regulations, as amended or superseded from time to time ("LGPD"). For the purposes of this Addendum 2, Personal Information has the meaning as defined in the LGPD.</p>

          <h3>Categories of Personal Information collected and processed</h3>
          <p>To find out what categories of your Personal Information are collected and processed, see "Personal Information We Collect" in the main portion of the Privacy Policy.</p>

          <h3>How we use your Personal Information</h3>
          <p>To find out how we process and use your Personal Information, including on what grounds, see "How We Use Your Personal Information" in the main portion of the Privacy Policy.</p>

          <h3>Your rights under the LGPD</h3>
          <p>The LGPD provides residents of Brazil with certain legal rights; these rights are not absolute and are subject to exemptions. In particular, you have the right to:</p>

          <p>• Ask whether we hold personal information about you and request copies of such personal information and information about how it is processed.</p>
          <p>• Restrict the processing of your personal information that is not being processed in compliance with the LGPD.</p>
          <p>• Obtain information on the possibility of refusing consent and the consequences of doing so.</p>
          <p>• Obtain information about the third parties with whom we share your personal information.</p>
          <p>• Obtain the deletion of your personal information being processed if the processing was based upon your consent, unless one or more exceptions provided for in Art. 16 of the LGPD apply.</p>
          <p>• Revoke your consent at any time.</p>
          <p>• Oppose a processing activity in cases where the processing is not carried out in compliance with the provisions of the law.</p>

          <p>To exercise your rights, please email us at support@wcoin.com</p>
          <p>Subject should say 'I have a data related question connected to my account'</p>

          <p>• Access. You have the right to access Information, and to receive an explanation of how we use it and who we share it with. This right is not absolute. For example, we cannot reveal trade secrets, or give you Information about other individuals.</p>
          <p>• Erasure. You have the right to request deletion of your Information. We may need to retain some of your Information where there are valid grounds for us to do so under data protection laws. For example, for the defence of legal claims, respect freedom of expression, or where we have an overriding legitimate interest to do so, but we will let you know when this is the case.</p>
          <p>• Note that where the Information is held by a third party data controller, such as a payment processor, we will use reasonable steps to inform them of your request, but we recommend you contact them directly in accordance with their own privacy policies to ensure your personal data is erased.</p>
          <p>• Objection and withdrawal of consent. You have the right to (i) withdraw your consent where you previously provided such consent; or (ii) object to our processing of your Information where we process such Information on the basis of our legitimate interests (see above under How we use your personal information). You may exercise this right as follows:</p>
          <p>- To stop receiving personalized advertising: please withdraw your consent in the in-app Settings. You can also find more information in our Cookie Policy.</p>
          <p>- To stop our cookies being placed: please refer to our Cookie Policy.</p>
          <p>• Portability. You have the right to receive a copy of Information we process on the basis of consent or contract in a structured, commonly used and machine-readable format, or to request that such Information is transferred to a third party.</p>
          <p>Correction:You have the right to correct any Information held about you that is inaccurate.</p>
          <p>Resriction:You have a right in certain circumstances to stop us processing Information other than for storage purposes</p>

          <h2>YOUR CHOICES ABOUT HOW INFORMATION ABOUT YOU IS USED AND SHARED</h2>
          <p>In many instances, you have choices about the information you provide and how we use that information.</p>

          <h3>Marketing Emails:</h3>
          <p>By providing us with an e-mail address, you acknowledge that we may use your e-mail address to communicate with you. You can opt-out of receiving promotional and other marketing emails from us by using the "unsubscribe" feature in our marketing e-mails.</p>

          <h3>Financial Incentives:</h3>
          <p>We may run promotions from time to time and ask you to share personal information with us. We will always give you clear notice about these types of programs when you sign up, and participation is always voluntary. If you change your mind, you will be able to opt out, and if you do not participate, you will still be able to use our services. To enter any such promotion, you will be asked to supply information about yourself, including personal data such as name, email address, or other information. This information is supplied by you on a voluntary basis. If you do not wish to provide personal information, you should not use the promotion.</p>

          <h2>7. CONTACT & COMPLAINTS</h2>
          <p>Questions, comments and requests regarding this Policy. These should be addressed to Wcoin LLC</p>

          <p>If you wish to make a complaint about how we process your Information, please contact us at support@wcoin.com and we will endeavour to deal with your complaint as soon as possible. This is without prejudice to your right to launch a claim with a data protection authority.</p>

          <p>We may need further information from them to verify you and will contact you to request further information if needed. We aim to respond to complaints within 30 days; however, this may be delayed if you have not provided us with all relevant information.</p>

          <h2>8. CHANGES</h2>
          <p>Any updates or changes to this Policy will be published here.</p>
        </div>
        <button onClick={handleBack} className="back-button">Back to Home</button>
      </div>
    </div>
  );
}

export const PrivacyPolicy = React.memo(PrivacyPolicyComponent); 