import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { MainPage } from "./main-page";
import { WhiteList } from "./white-list";
import { PrivacyPolicy } from "./privacy-policy";
import { TermsOfUse } from "./terms-of-use";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/whitelist" element={<WhiteList />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-use" element={<TermsOfUse />} />
      </Routes>
    </Router>
  );
}

export default App;
