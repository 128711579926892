import React from "react";

import { useNavigate } from "react-router-dom";

import { ClaimButton } from "../claim-button";
import BlinkingsStars from "../blinkings-stars";

import "../App.css";

const mainBg = require("../img/main-bg.png");
const text = require("../img/text.svg").default;
const wcoinIcon = require("../img/w-coin.svg").default;
const xIcon = require("../img/x.svg").default;
const tgIocn = require("../img/tg.svg").default;

function MainPageComponent(){
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(window.location.search);
  const showWhitepaper = searchParams.get('showWhitepaper');

  console.log(showWhitepaper);

  const handleClick = () => {


    if (showWhitepaper === "true") {
      window.open('./whitepaper.pdf', '_blank');
    } else {
      navigate("/whitelist");
    }
  };

  return (
    <div className="app" style={{ backgroundImage: `url(${mainBg})`, }}>
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div className="app-wrapper">
          <ClaimButton />
          <div className="app-info-area">
            <img alt="text" src={text} width="330px" />
            <div className="app-social-wrapper">
              <a
                href="https://t.me/wcoin_tapbot"
                target="_blank"
                rel="noreferrer"
              >
                <img alt="text" src={wcoinIcon} />
              </a>
              <div className="divider"></div>
              <a href="https://x.com/wcoin_io" target="_blank" rel="noreferrer">
                <img  alt="text" src={xIcon} />
              </a>
              <div className="divider"></div>
              <a
                href="https://t.me/w_community"
                target="_blank"
                rel="noreferrer"
              >
                <img alt="text" src={tgIocn} />
              </a>
            </div>
          </div>
        </div>
        <button onClick={handleClick} className="whitePaperBtn">{showWhitepaper === "true" ? "Whitepaper" : "W-hitepaper"}</button>
      </div>
      <div className="reserved">
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <span>© 2025 WCoin. All rights reserved.</span>
          <div className="footer-links">
            <a href="/privacy-policy">Privacy Policy</a>
            <span>|</span>
            <a href="/terms-of-use">Terms of Use</a>
          </div>
        </div>
      </div>
      <BlinkingsStars />
    </div>
  );
};

export const MainPage = React.memo(MainPageComponent);

